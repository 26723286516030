import React from 'react'
import Shucks, { sanitiseMoney } from './Shucks'
import * as firebase from 'firebase'
import { transfer } from './transactions'

const emailRegexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

class Transfer extends React.PureComponent {
  state = {email: '', amount: 20}

  updateAmount(amount) {
    this.setState({amount: sanitiseMoney(amount)})
  }

  transfer = async (ev) => {
    console.log("Transfer")
    ev && ev.preventDefault()
    const {email, amount} = this.state
    if (!email.match(emailRegexp)) {
      console.error(`Email ${email} did not match regexp`)
      return
    }
    const {accountStatus: {balance}, economy: {total}} = this.props

    await transfer({email, amount, balance, total})
    this.setState({email: '', amount: 20})
  }


  render () {
    return (
      <form className='Transfer' onSubmit={this.transfer}>
        <h2>Transfer</h2>
        <div>
          <Shucks><input type='number' value={this.state.amount} onChange={({target: {value}}) => this.updateAmount(value)}/></Shucks>
        </div>
        <div className='field'>
          <input type='email'
                 value={this.state.email}
                 placeholder='someone@somewhere.com'
                 onChange={({target: {value}}) => this.setState({email: value})} />
        </div>
        <div className='field'><button type='submit'>Transfer</button></div>
      </form>
    )
  }
}

export default Transfer