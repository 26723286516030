import { isEmpty } from 'lodash'
import React from 'react'
import { encode } from 'firebase-key'
import * as firebase from 'firebase'
import { receive } from './transactions'

export default class TransferReceiver extends React.PureComponent {
  componentDidMount() {
    this.ref = firebase.database()
      .ref(`accounts/incoming/${encode(this.props.email)}`)
    this.ref
      .on('child_added', (snapshot) => receive(snapshot.val(), snapshot.key))
  }

  componentWillUnmount() {
    this.ref.off('child_added')
  }

  render() {
    return null
  }
}
