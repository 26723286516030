import React, { Component } from 'react'
import './About.sass'
import Markdown from 'react-markdown'

const spiel =
  `
## Shucks are better than any cryptocurrency in every way for day-to-day transactions.
- real-time currency
- totally fee free
- all transactions private
- verified identities
- secured by your Google account
- minimal trust surface
- negligible environmental impact
- decentralised transaction handling
- cryptographically protected with SSL

And they're *still* worthless. If you want to transfer wealth, please try 'money', available at many banks.

---
<small>**Notice:** Just like with other digital currencies, and everyone and everything you ever loved, all your shucks can and will vanish at any time without warning or reason so don't come crying to me. I don't care and neither should you.</small>

<small>Using this service for any reason is *highly discouraged* and may result in severe fines and imprisonment.</small>

`
const faq = `

## Are you sick of not having as much money as you want whenever you want?

That's because you don't have any **shucks**, a worthless digital "currency" anyone can inflate or deflate *in real time*.

### How do I get shucks?
Login with your Google account and just **mine them**. If you're lucky and not too greedy you can inflate your way into billions by making shucks *somehow even more worthless*.

### What can I do with shucks?
You can **send them to anyone** by their Google account email address. Make sure you tell them, because we won't email them, but their shucks will be right here waiting for them. 

- give them as confusing gifts to people you hate
- trick a grandma into buying you a pizza
- enjoy a constant feeling of impending doom

You can also **burn them**. That's right, burn "money" like a world leader! It makes all the other shucks more valuable, but still worthless.
Feel the satisfaction of freeing yourself, the warmth of growing common property, the sociopathic fizz of pointless destruction, and maybe game mining so you can get even more worthless shucks. With burning, the possibilities are truly limitless.`

class About extends Component {
  render () {
    return (
      <div className="About">
        <div className='description'>
          <Markdown className='faq'>{faq}</Markdown>
          <div className='spiel'>
            <Markdown className='spiel-text' escapeHtml={false}>{spiel}</Markdown>
            <div className='site-footer'>
              <hr />
              <div className='byline'>
                <div className='by'>by <strong>Leon Spencer</strong></div>
                <div className='date'>© 2018</div>
              </div>
              <div className='links'>
                <div className='personal'>
                  <a href='https://lfs.itch.io/' target='_blank'>itch.io</a>
                  <a href='https://www.twitter.com/alazoral' target='_blank'>twitter</a>
                  <a href='https://www.linkedin.com/in/leonfspencer/' target='_blank'>in</a>
                </div>
                <div className='legal'>
                  <a href='/privacy.pdf' target='_blank'>Privacy Policy</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    )
  }
}

export default About
