import React from 'react'
import Shucks, { sanitiseMoney } from './Shucks'
import { mine } from './transactions'

export default class Mine extends React.PureComponent {
  state = {amount: 1}

  mine = (ev) => {
    ev && ev.preventDefault()
    const {amount} = this.state
    console.log(`Mining ${amount}`)
    this.setState({wasSuccess: 'working'}, async () => {
      const {accountStatus: {balance}, economy: {total}} = this.props
      this.setState({wasSuccess: await mine({amount, balance, total})})
    })
  }

  updateAmount(amount) {
    this.setState({amount: sanitiseMoney(amount), wasSuccess: null})
  }


  render() {
    const {wasSuccess} = this.state

    return (
      <form className='Mine' onSubmit={this.mine}>
        <h2>Mine</h2>
        <Shucks><input type='number' value={this.state.amount} onChange={({target: {value}}) => this.updateAmount(value)}/></Shucks>
        <div className='results'>
          {wasSuccess === true && <>🤑 Success!</>}
          {wasSuccess === false && <>🤷‍♂️ Try again</>}
          {!wasSuccess && wasSuccess !== false && <><b>Did you know:</b> Larger amounts are trickier to mine</>}
          {wasSuccess === 'working' && <>⛏ Mining...</>}

        </div>
      </form>
    )
  }
}
