import React from 'react'
import Shucks, { sanitiseMoney } from './Shucks'
import { burn } from './transactions'
import './Burn.sass'
import fireVideo from './fire.mp4'

export default class Burn extends React.PureComponent {
  state = {amount: 0}

  mine = (ev) => {
    ev && ev.preventDefault()
    const {amount} = this.state
    console.log(`Burning ${amount}`)
    this.setState({wasSuccess: null}, async () => {
      const {accountStatus: {balance}, economy: {total}} = this.props
      this.setState({wasSuccess: await burn({amount, balance, total})})
    })
  }

  updateAmount(amount) {
    this.setState({amount: sanitiseMoney(amount)})
  }


  render() {
    const {wasSuccess} = this.state

    return (
      <form className='Burn' onSubmit={this.mine}>
        <video autoPlay muted loop playsInline className='fire'>
          <source src={fireVideo} type='video/mp4'/>
        </video>
        <h2>Burn</h2>
        <Shucks><input type='number' value={this.state.amount} onChange={({target: {value}}) => this.updateAmount(value)}/></Shucks>
        <div className='results'>
          {wasSuccess === true && <>Success!</>}
        </div>
      </form>
    )
  }
}
