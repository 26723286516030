import firebase from 'firebase'
import { encode } from 'firebase-key'
import { chain, mapKeys } from 'lodash'
import Queue from 'promise-queue'

const transaction = async ({amount, isSending, email, balance, total}) => {
  console.info(`${isSending ? 'Sending' : 'Taking'} ${amount} to ${email} from ${balance}`)
  const me = firebase.auth().currentUser
  const uid = me.uid
  if (amount === 0) { return }
  if (email === me.email) { return }
  if (isSending && balance < amount) { return false}

  amount = Math.abs(amount)

  const relAmount = (isSending ? -1 : 1) * amount

  const ref = firebase.database().ref()
  const transactionId = ref.push().key
  const at = firebase.database.ServerValue.TIMESTAMP
  const transData = {
    at,
    isSending: !!isSending,
    amount
  }

  if (email) { transData.email = email }

  let updates = {
    [`accounts/status/${uid}/balance`]: (balance || 0) + relAmount,
    [`accounts/transactions/${uid}/${transactionId}`]: transData,
  }

  if (email) {
    updates[`accounts/incoming/${encode(email)}/${transactionId}`] = {
      at,
      amount,
      email: me.email,
    }
  } else {
    updates['economy/total'] = (total || 0) + relAmount
  }

  try {
    await ref.update(updates)
    console.log('Success!')
    return true
  } catch (err) {
    console.log('Fail!', err)
    return false
  }
}

export const mine = ({amount, balance, total}) => transaction({amount, balance, total})
export const burn = ({amount, balance, total}) => transaction({amount, isSending: true, balance, total})
export const transfer = ({amount, balance, total, email}) => transaction({
  amount: Math.abs(amount),
  isSending: true,
  balance, total, email
})


let receiveQueue = new Queue(1)

export const receive = (incoming, transactionId) => {
  let retries = 5
  const doIt = async () => {
    const {email, uid} = firebase.auth().currentUser

    const at = firebase.database.ServerValue.TIMESTAMP

    const balanceValue = (await firebase.database().ref(`accounts/status/${uid}/balance`).once('value')).val()
    const balance = typeof balanceValue === 'number' ? balanceValue : 0
    console.info(`Receiving'} ${incoming.amount} to ${email} from ${balance} ${balanceValue}`)

    const updates = {
      [`incoming/${encode(email)}/${transactionId}`]: null,
      [`transactions/${uid}/${transactionId}`]: {
        at, amount: incoming.amount, email: incoming.email
      },
      [`status/${uid}/balance`]: balance + incoming.amount
    }
    console.log("Updating", updates)

    try {
      await firebase.database().ref('accounts').update(updates)

    } catch (err) {
      console.error(`Failed. Retrying ${retries} more.`, err, incoming, transactionId)
      if (retries-- > 0) {
        await doIt()
      }
    }
  }

  return receiveQueue.add(doIt)
}