import React from 'react'
import { Route, Switch } from '@upgrowth/reactkit'
import About from './About'
import Transfer from './Transfer'
import { keys, map } from 'lodash'
import { firebind } from '@upgrowth/firekit'
import Shucks from './Shucks'
import './Account.sass'
import Mine from './Mine'
import Burn from './Burn'
import TransferReceiver from './TransferReceiver'
import numeral from 'numeral'

const percent = (amt) => numeral(amt).format('0.00000%')
const Account = ({user, accountStatus = {balance: 0}, latestTransactions, economy = {total: 1}, loading}) => (
  <div className='Account'>
    <TransferReceiver email={user.email} />
    <div className='sidebar'>
      <div className='balance'>
        <h3>{user.displayName} – {user.email}</h3>
        <div className='shucks'>
          <Shucks>{accountStatus.balance || 0}</Shucks>
          <div className='econ'>
            <div className='percent'>{percent((accountStatus.balance || 0) / (economy.total || 1))}</div>
            <div className='total'>of 🌐<Shucks native>{numeral(economy.total).format('0.0a')}</Shucks></div>
          </div>
        </div>
      </div>
      <div className='transactions'>

        <h3>Transactions</h3>
        <table>
          {/*<thead>*/}
          {/*<tr>*/}
          {/*<th>Direction</th>*/}
          {/*<th>Account</th>*/}
          {/*<th>Amount</th>*/}
          {/*</tr>*/}
          {/*</thead>*/}
          <tbody>
          {keys(latestTransactions).sort().reverse().map(transKey => {
            const {isSending, email, amount} = latestTransactions[transKey]
            return (
              <tr key={transKey}>
                <td><Shucks native>{amount}</Shucks></td>
                <td>{isSending
                  ? <>
                    <span className='text'>Sent to</span>
                    <span className='icon'>➡️</span>
                  </>
                  : <>
                    <span className='text'>Received from</span>
                    <span className='icon'>⬅️</span>
                  </>
                }</td>
                <td>{email || (isSending ? 'Burning' : 'Mining')}</td>
              </tr>
            )
          })}
          </tbody>
        </table>
      </div>
    </div>
    <div className='info'>
      <Switch>
        <Route name='mine' render={() => <Mine {...{user, accountStatus, economy}} />} />
        <Route name='burn' render={() => <Burn {...{user, accountStatus, economy}} />} />
        <Route name='transfer' render={() => <Transfer {...{user, accountStatus, economy}} />} />
        <Route name='home' exact component={About} />
      </Switch>
    </div>
  </div>
)

export default firebind(Account, {
  accountStatus: ({user: {uid}}) => `accounts/status/${uid}`,
  latestTransactions: ({user: {uid}}) => ({
    ref: `accounts/transactions/${uid}`,
    limitToLast: 100,
    orderByKey: true
  }),
  economy: `economy`
})