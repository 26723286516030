import React from 'react'
import firebase from 'firebase'

const crash = () => {throw new Error("Auth context not found")}

const {Provider, Consumer} = React.createContext({user: null, login: crash, logout: crash})


export const withAuth = (Component) => (
  (innerProps) => (
    <Consumer>
      {(context) => <Component {...innerProps} {...context} />}
    </Consumer>
  )
)

export class AuthProvider extends React.PureComponent {
  state = {user: null}
  componentDidMount() {
    this.unsub = firebase.auth().onAuthStateChanged(this.handleAuthChanged)
  }

  componentWillUnmount() {
    this.unsub && this.unsub()
    delete this.unsub
  }

  handleAuthChanged = (user) => this.setState({user})

  login = () => {
    const provider = new firebase.auth.GoogleAuthProvider()
    return firebase.auth().signInWithPopup(provider)
  }

  logout = () => firebase.auth().signOut()

  render() {
    return <Provider value={{...this.state, login: this.login, logout: this.logout}}>{this.props.children}</Provider>
  }
}

export const Auth = Consumer