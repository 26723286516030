import React from 'react'
import ReactDOM from 'react-dom'
import './index.sass'
import * as serviceWorker from './serviceWorker'
import App from './App'
import firebase from 'firebase'
import {firekit} from '@upgrowth/firekit'

firekit(firebase.initializeApp({
  "apiKey": "AIzaSyA01u-rKgohmKQi2gmCirniDTBkkZCf4WU",
  "databaseURL": "https://lfs-shucks.firebaseio.com",
  "storageBucket": "lfs-shucks.appspot.com",
  "authDomain": "lfs-shucks.firebaseapp.com",
  "messagingSenderId": "971072928540",
  "projectId": "lfs-shucks"
}))

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
