import { BrowserRouter } from 'react-router-dom'
import { Routes, Switch, Route, NavLink } from '@upgrowth/reactkit'
import React from 'react'
import routes from './routes'
import About from './About'
import './App.sass'
import { Auth, AuthProvider } from './Auth'
import Account from './Account'

const Nav = () =>
  <nav>
    {/*<NavLink name='home' className='about' exact>about</NavLink>*/}
    <Auth>{({user, logout, login}) => (
      user
        ? <>
          <NavLink name='transfer'>
            <span className='icon'>💸</span>
            <span className='text'>transfer</span>
          </NavLink>
          <NavLink name='mine'>
            <span className='icon'>⛏</span>
            <span className='text'>mine</span>
          </NavLink>
          <NavLink name='burn'>
            <span className='icon'>🔥</span>
            <span className='text'>burn</span>
          </NavLink>
          <a onClick={logout}>
            <span className='icon'>👋</span>
            <span className='text'>logout</span>
          </a>
        </>
        : <a onClick={login}>get started</a>
    )}</Auth>
  </nav>

const App = () => (
  <AuthProvider>
    <BrowserRouter>
      <Routes routes={routes}>
        <div className='App'>
          <header>
            <NavLink name='home'><h1>$hucks</h1></NavLink>
            <h2>a worthless “currency” for a worthless world</h2>
          </header>
          <Nav/>

          <Auth>{({user}) => user
            ? <Account user={user}/>
            : <Route name='home' component={About} />
          }</Auth>
        </div>

      </Routes>
    </BrowserRouter>
  </AuthProvider>
)

export default App